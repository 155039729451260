$(function(){
    // top menu toggle
    $(".menu_open .menu_icon").on("click", function () {
        $(".nav_items").toggleClass("nav_open");
        if ($(window).width() < 1025) {
            $("body").toggleClass("menu-active");
        }
    });
    $(".nav_close").on("click", function () {
        $(".nav_items").removeClass("nav_open");
        if ($(window).width() < 1025) {
            $("body").removeClass("menu-active");
        }
    });

    // top menu
    function applyNavigationToggle() {
        $('.header_wrapper .nav_items .nav_link a .expand').off('click');
        $('.sub_menu .nav_collapse').off('click');
        if (window.innerWidth < 1025) {
            $('.header_wrapper .nav_items .nav_link a.show-children').on('click', function(e) {
                e.preventDefault();
                let $parent = $(this).parents('div');
                $parent.siblings().find('.sub_menu').removeClass('active');
                $parent.children('.sub_menu').addClass('active');
            });

            $('.sub_menu .nav_collapse').on('click', function(e) {
                e.preventDefault();
                $(this).closest('.sub_menu').removeClass('active');
            });
        }
    }
    applyNavigationToggle();
    $(window).resize(function() {
        applyNavigationToggle();
    });
});
